:root {
  --loader-background-color: #EEEEEE;
  --loader-highlight-color: #DEDEDE;
}


@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}


//blog-treding
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

//modals
.iq-modal {
  >.modal-dialog {
    max-width: 1400px;
  }
}

//file-manager dropzone
.file-manager-modal {
  .dzu-dropzone {
    min-height: 500px;
    overflow: hidden;
    border: 1px solid #8a92a6;
  }
}

.dark {
  .file-manager-modal {
    .dzu-dropzone {
      background-color: #151824;
    }
  }
}

.btn-upload {
  input[type="file"]::file-selector-button {
    background-color: var(--bs-primary);
    border-radius: 0.25rem;
    box-shadow: none;
    color: white;
    border: 1px solid var(--bs-primary);
  }

  input[type="file"]::file-selector-button:hover {
    background-color: rgba(var(--bs-primary), 0.9);
  }
}

//dark choices

.dark .choices[data-type*="select-one"] {
  .choices__input {
    background: #222738;
    border-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.dark .is-open .choices__list[aria-expanded] {
  border-color: rgba(#ffff, 0.1);
}

.bordred-image {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.5rem;
  padding: 1rem;

  .image-fluid {
    border-radius: 0.5rem;
  }
}

@media (min-width: 1023px) {
  .iq-guru-video {
    height: 20rem;
  }
}

.iq-file-manager {

  .iq-video-hover {
    .btn-icon {
      background: #fff;
      height: 3.125rem;
      left: 40%;
      opacity: 0;
      top: 40%;
      width: 3.125rem;
      opacity: 1;
    }

    .iq-video-button {
      left: 23% !important;
      top: 29% !important;
    }

    .iq-video-button-list {
      left: 32% !important;
      top: 29% !important;
    }

    @media screen and (max-width: 575px) {
      .iq-video-image {
        width: 100% !important;
        height: 164px !important;
      }

      .iq-video-button {
        left: 43% !important;
      }
    }
  }
}

// accordian
.iq-accordian {
  .card {
    background-color: #f9f9f9;
    border: var(--bs-border-width) solid var(--bs-border-color);

    .active {
      background-color: var(--bs-primary);
      color: var(--bs-white);

      .btn-light {
        background-color: var(--bs-border-color);
        color: var(--bs-primary);
      }
    }

    .card-header {
      padding: 1rem;
      border: var(--bs-border-width) solid var(--bs-border-color);
    }
  }

  [aria-expanded="false"] {
    .right-icon {
      transition: var(--sidebar-transition);
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease-in-out);
      transform: rotate(0deg);
    }
  }

  .btn[aria-expanded="true"] {
    .right-icon {
      transition: var(--sidebar-transition);
      transition-duration: var(--sidebar-transition-duration);
      transition-timing-function: var(--sidebar-transition-function-ease-in-out);
      transform: rotate(90deg);
    }
  }
}

// card
.iq-product-list-view {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .inner-card.card {
    border: var(--bs-border-width) solid var(--bs-border-color);
    box-shadow: none;
    margin-bottom: 0;

    .card-body {
      border-bottom: var(--bs-border);
    }

    .card-footer {
      background-color: #f9f9f9;
    }
  }
}

.min-h-5 {
  min-height: 5rem;
}

.chat-bg-left {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.no-border {
  border: none !important;
}

.iq-skeleton-logo {
  background: black;
  height: 48px;
  width: 100px;
}

.iq-skeleton {
  background: linear-gradient(90deg,
      var(--loader-background-color) 25%,
      var(--loader-highlight-color) 50%,
      var(--loader-background-color) 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  border-radius: var(--bs-border-radius);
}

.iq-img-skeleton {
  background: linear-gradient(90deg,
      var(--loader-background-color) 25%,
      var(--loader-highlight-color) 50%,
      var(--loader-background-color) 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  border-radius: var(--bs-border-radius);
  height: 48px;
  width: 100px;
}

// sweetalert
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

// scroll
.scroll {

  /* width */
  ::-webkit-scrollbar {
    width: 0.25em;
    height: 0.25em;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 0.313em;
    border-radius: 0.313em;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--bs-primary);
    -webkit-border-radius: 0.313em;
    border-radius: 0.313em;
  }
}

.top-40 {
  top: 40%;
}

.top-15 {
  top: 15%;
}

.rotate-180 {
  transform: rotate(180deg);
}

h1 {
  font-weight: 500;
  line-height: 76px;
}

.text-underline {
  text-decoration: underline !important;
}

.bg-secondary-10 {
  background-color: var(--bs-secondary-tint-90);
}

.lh-30 {
  line-height: 30px;
}

// sub-cyive
.iq-sub-active .active {
  background-color: var(--bs-secondary-tint-90);
  color: var(--bs-secondary);
  box-shadow: none;
}

.z-soft-badge {
  font-weight: 400;
  color: var(--bs-secondary);
}

.z-accordian {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .card {
    margin-bottom: unset !important;
  }
}

.z-btn-border.btn-border {
  color: var(--bs-black);
}

//background

.z-bg-color {
  background-color: var(--bs-body-bg);
}

/**
* ==============================================
* Dot Flashing
* ==============================================
*/
.dot-flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: var(--bs-secondary);
  color: var(--bs-secondary);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: var(--bs-secondary);
  color: var(--bs-secondary);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--bs-secondary);
  }

  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.z-form {
  .form-label {
    color: #070d0a;
  }

  .form-control {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
  }
}

.z-border-radius {
  border-radius: 50%;
}

.z-suggetion-card {
  background-color: #f9f9f9;
}

.z-support-card {
  border: 1px solid #f0f0f0;
  border-radius: 0.25rem;
  padding: 0.7rem;
}

.z-counter-input {
  width: 10rem;
  background-color: #ffffff;
}

.z-border-bottom {
  border-bottom: 1px solid #38b349 !important;
}

.z-carbon-card {
  position: relative;

  img {
    position: absolute;
    right: 0;
    margin-top: -2.9rem;
  }

  .z-image-end {
    position: absolute;
    right: 1rem;
    margin-top: -1rem;
  }
}

.z-bordeer-top {
  border-top: 1px solid #d9d9d9 !important;
}

.flex-shrink-2 {
  flex-shrink: 2 !important;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

// Range Slider css
.horizontal-slider {
  width: 100%;
}

.example-track.example-track-1 {
  background: #38b349 !important;
}

.horizontal-slider .example-track {
  height: 8px;
  background: #eee;
  border-radius: 25px;
}

.vimeo-iframe iframe {
  width: 100% !important;
  aspect-ratio: 1/1;
  object-fit: cover;
  height: unset;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.iq-event-loader {
  border-radius: "4px";
  background: "var(--loader-background-color)";
  animation: "loading 2s infinite ease-in-out";
}

.active-primary {
  background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
  border: none;
}

.iq-product-event-view {
  .inner-card.card {
    border: var(--bs-border-width) solid var(--bs-border-color);
    box-shadow: none;
    margin-bottom: 0;

    .card-body {
      border-bottom: var(--bs-border);
    }

    .card-footer {
      background-color: #f9f9f9;
    }
  }
}

@media (max-width: 599px) {
  .iq-event-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  .avatar-sm-60 {
    height: 3.75rem;
    width: 3.75rem;
    min-width: 3.75rem;
    text-align: center;
    line-height: 3.75rem;
    border-radius: 0.25rem;
  }

  .toolkit-table {
    .table-content-small {
      -webkit-line-clamp: unset !important;
    }
  }
}

@media (min-width: 600px) {
  .iq-event-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .iq-event-image-grid {
    width: 140px;
    height: 140px;
    object-fit: cover;
  }

  .iq-event-input {
    width: 25%;
  }

  .iq-product-grid-view {
    .iq-images {
      width: 1.875rem !important;
    }

    img {
      border: var(--bs-border-width) solid var(--bs-border-color);
      object-fit: contain;
      width: 80px;
      height: 80px;
    }

    .iq-video-image {
      width: 120px !important;
      height: 120px !important;
    }
  }
}

.iq-product-grid-view,
.iq-product-list-view {
  .iq-images {
    width: 1.875rem !important;
    min-height: 1.875rem !important;
  }
}

/* Base select styles */
.filter-select {
  width: 16rem;

  .iq-select {
    width: 100%;
    padding: 10px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 4px;
    background-color: var(--bs-white);
    transition: all 0.3s ease;
    /* Smooth transition for hover and focus */
  }
}

.short-1.expanded,
.short-2.expanded {
  -webkit-line-clamp: unset !important;
}

.custom-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
  background-color: var(--bs-gray-400);
  border-radius: 1.5rem;
  padding: 0.3rem;
  cursor: pointer;
}

.toggle {
  flex: 1;
  text-align: center;
  padding: 4px;
  /* Padding for the toggle */
  border-radius: 20px;
  /* Rounded corners */
  transition: background-color 0.3s;
  /* Smooth transition */
}

.toggle.active {
  background-color: var(--bs-primary);
  /* Active background color */
  color: var(--bs-white);
  /* Text color when active */
}

.toggle-label {
  font-weight: bold;
  /* Bold text */
}

.iq-chating-content {
  a {
    text-decoration: underline;
    color: var(--bs-secondary) !important;
  }
}

// Reusable classes
.cursor-pointer {
  cursor: pointer;
}

.hover-underline {
  transition: 0.3s ease-in-out;

  &:hover {
    text-decoration: underline;
    transition: 0.3s ease-in-out;
  }
}

.scrollable-x-nav {
  overflow-x: auto;

  // scroll bar
  /* width */
  &::-webkit-scrollbar {
    width: 0.25em;
    height: 0.45em;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 0.313em;
    border-radius: 0.313em;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--bs-primary);
    -webkit-border-radius: 0.313em;
    border-radius: 0.313em;
  }
}

.dark {
  .iq-accordian {
    .advice-background {
      background-color: var(--body-bg) !important;
      border: 1px solid;
    }
  }

  .iq-suggest-support,
  .iq-communities {
    background-color: var(--body-bg) !important;
    border: 1px solid;
  }

  .toolkit-table {
    td {
      background-color: var(--bs-black) !important;
      box-shadow: inset 0 0 0 var(--bs-black) !important;
      // box-shadow: inset 0 0 0 #090a09;
    }
  }

  .maximized {
    border-radius: 10px !important;
  }

  .bi.bi-paperclip {
    fill: var(--bs-white) !important;
  }

  .modal-header {
    background-color: transparent !important;
  }

  .model-close-icon path {
    stroke: var(--bs-white) !important;
  }

  .iq-product-event-view .inner-card.card .card-footer {
    background-color: transparent !important;
  }

  .choices__list--dropdown {
    color: var(--bs-white) !important;
  }

  .choices[data-type*=select-one] .choices__input {
    background: var(--bs-white) !important;
  }

  .nav-iconly.nav-event {
    .nav-link {
      flex-direction: row !important;
      padding: 0.5rem !important;
    }
  }

  .z-form {
    .form-label {
      color: var(--bs-white) !important;
    }

    .form-control,
    .dropdown-custom {
      background-color: transparent !important;
      border-color: var(--body-bg) !important;
    }

    .dropdown-custom {
      border: 1px solid white !important;
    }
  }

  textarea {
    background: transparent !important;
  }

  .tasklist .tasklist-title {
    background: transparent !important;
  }
}

/* Style for the selected card */
.iq-guru-selected {
  .card {
    border: 2px solid transparent;
  }

  .selected {
    border: 2px solid var(--bs-primary);
    background-color: var(--bs-secondary-tint-90);

    .selected-checked-icon {
      position: absolute;
      top: -15px;
      right: -15px;
    }
  }
}

.iq-guru-icon {
  fill: var(--bs-primary)
}

.iq-swiper-tabs {
  .active {
    background-color: var(--bs-primary);
    color: var(--bs-white);
  }

  .hidden {
    background: transparent;
  }

  .hidden::after {
    display: none;
  }

  .show::after {
    display: block;
  }
}

.arrow-down-wrapper {
  position: fixed;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  background: #fff;
  border-radius: 50px;
  padding: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  @media screen and (max-width: 767px) {
    bottom: 85px;
    left: 10%;

    .no-mob {
      display: none;
    }
  }
}

.iq-info-icon {
  right: 33%;
  top: 10%;
  cursor: pointer;
  z-index: 1;
}

@media (min-width: 769px) and (max-width: 1160px) {
  .iq-info-icon {
    right: 33%;
  }
}

@media (max-width: 768px) {
  .video_container {
    height: auto !important;
  }

  #my-scrollbar {
    height: 100% !important;
  }
}

.iq-info-icon {
  right: 33%;
}

@media ( max-width: 520px ) {
  .iq-info-icon {
    right: 37%;
  }
}

.iq-guru-scroll {
  max-height: 10rem !important;
}

  .sd-row.sd-clearfix.sd-row--multiple>div:first-child {
    flex: none !important;
    min-width: min(100%, 460px) !important;
  }

  .sd-navigation__prev-btn, .sd-navigation__next-btn, .sd-navigation__complete-btn {
    background-color: var(--bs-secondary) !important;
    color: #ffffff !important;
  }

  [data-key="sex-1"],
  [data-key="birthDate0"] {
    .sd-row__question.sd-question--title-bottom {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .sd-container-modern .sd-title.sd-container-modern__title {
    display: none !important;
  }


.sd-title.sd-container-modern__title {
  display: none !important;
}

.sd-input:focus {
  box-shadow: var(--sjs-shadow-inner-reset, inset 0px 0px 0px 0px #eeeff1), 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #eeeff1)) !important;
}

.sd-item--checked .sd-item__decorator {
  background: var(--bs-secondary) !important;
}

.sd-item__control:focus+.sd-item__decorator {
  box-shadow: var(--sjs-shadow-inner-reset, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)),0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #00008b)) !important;
}

.sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator:after {
  background-color:#eeeff1 !important;
}

.iq-partner-modal{
  [data-vimeo-initialized="true"] {
    iframe{
      width: 100% !important;
    }
  }
}