/*!
* Version: 1.1.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";
// Variables
@import "./hope-ui-design-system/variable";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";

@import "./bootstrap/mixins";

// Hope Ui Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./custom/auth/authentication";
@import "./custom/kanban/kanban";
@import "./custom/pricing/pricing";
@import "./custom/ui-kit/ui-kit";


// :root {
//     --bs-primary: #F8B71B;
//     --bs-secondary: #0376BC;
//     --bs-tertiary: #469045 ;
//     --bs-bg-color-1:#469045;
//     --bs-bg-color-2:#469045;
//     --bs-border:#469045;
//     --bs-heading-dark:#469045;
//     --bs-heading-light:#469045;
//     // --bs-body-color:;
// }

// @media (max-width: 576px) {
//     .btn.btn-link {
//       display: block;
//     }
//   }

//   @media (min-width: 577px) {
//     .btn.btn-link {
//       display: none;
//     }
//   }

* {
    font-family: var(--bs-body-font-family);
    /* Fallback to sans-serif */
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Initial state */
  .App {
    opacity: 0;
    animation: fadeIn 5s ease-in-out forwards;
  }

.container {
    max-width: 1400px;
}

.RSPBprogression {
    background: #38B349 !important;
}

.progress-step {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    background: #38B349;
    color: white;
}

.accomplished-progress {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #38B349;
}

// .custom-dropdown .dropdown-toggle::after {
//     content: '';
//     display: inline-block;
//     width: 0;
//     height: 0;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     border-top: 0.3em solid;
//     border-right: 0.3em solid transparent;
//     border-bottom: 0;
//     border-left: 0.3em solid transparent;
// }
.dropdown-toggle {
    width: 100%;
    justify-content: space-between
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--bs-primary);
}

.get-support-details {
    width: 400px;
    padding: 10px;
    font-family: Bicyclette;
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    color: #070D0A,
}

.RSPBprogressBar {
    display: flex;
    justify-content: space-between;
}

.progress-bar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.progress-bar-wrapper {
    width: 50%;
    /* Adjust width as needed */
}

.step-indicator {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 10px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
}

.step-indicator span {
    display: block;
}

.step-indicator.accomplished {
    background-color: #005826;
    color: #fff;
    border-color: #005826;
}

.custom-dropdown {
    width: 524px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    background-color: #F9F9F9;
    justify-content: center;
    border-radius: 8px;
    height: 295px;
    overflow-y: auto;
    padding: 35px;
}

.custom-dropdown::-webkit-scrollbar {
    width: 8px;
}

.custom-dropdown::-webkit-scrollbar-track {
    background: #f2f2f2;
}

.custom-dropdown::-webkit-scrollbar-thumb {
    background: #005826;
    border-radius: 6px;
}

.option {
    .custom-dropdown-btn {
        display: inline-block;
        width: 100%;
        padding: 10px 20px;
        cursor: pointer;
        background: var(--bs-white);
        text-align: center;
        border-radius: 4px;
    }

    &.selected {
        .custom-dropdown-btn {
            background-color: var(--bs-secondary);
            color: var(--bs-white);
        }
    }
}

.progress-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .progress-count {
        display: inline-block;
        flex-shrink: 0;
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
        background-color: transparent;
        color: var(--bs-primary);
        border: 2px solid var(--bs-primary-tint-80);
        border-radius: 50%;

        &.active {
            background-color: var(--bs-secondary);
            color: var(--bs-white);
            border-color: transparent;
        }
    }

    .progress-bar-line {
        height: 4px;
        width: 100px;
        border-radius: 40px;
        background-color: var(--bs-primary-tint-90);
    }
}

.progress-bar-line.active-line {
    background-color: var(--bs-secondary);
}

.dropdown {
    .dropdown-toggle {
        &.dropdown-custom {
            position: relative;
            padding-right: 50px;

            &::after {
                content: "";
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/></svg>');
                height: 14px;
                width: 14px;
                border: none;
                margin: 0;
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%) rotate(0);
            }

            &::before {
                content: "";
                background-color: var(--bs-border-color);
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 45px;
            }
        }
    }

    &.show {
        .dropdown-toggle {
            &.dropdown-custom {
                &::after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
    }
}

// teambox
.team-box {
    display: flex;
    gap: 10px;
    padding: 16px;
    border-radius: var(--bs-border-radius);
    background-color: var(--bs-border-color);

    .team-box-image {
        flex-shrink: 0;

        img {
            width: 250px;
            height: 250px;
            object-fit: cover;
            border-radius: var(--bs-border-radius);
        }
    }
}

.font-size-10 {
    font-size: 10px;
}

@media(max-width: 1349px) {
    .team-box {
        .team-box-image {
            img {
                height: 210px;
                width: 210px;
            }
        }
    }
}

@media(max-width: 1199px) {
    .team-box {
        .team-box-image {
            img {
                height: 100%;
                width: 170px;
            }
        }
    }
}

@media(max-width: 1099px) {
    .team-box {
        display: block;

        .team-box-image {
            margin-bottom: 30px;

            img {
                height: 100%;
                width: 100%;
                max-height: 220px;
            }
        }
    }
}

.select-theme {
    border: 1px solid var(--bs-secondary);
}

.error-message {
    color: black !important;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    font-weight: 500;
    background: #ffe4e4;
    padding: 9px;
    border-top: 2px solid red;
}



.guruImage {
    height: 50px;
    width: 50px;
    border-radius: 30px;
}

.shepherd-element {
    max-width: 421px;
    border-radius: 1rem;

    * {
        max-width: 100%;
    }

    .shepherd-content {
        max-height: fit-content;
        border-radius: 1rem;

        .shepherd-header,
        .shepherd-text,
        .shepherd-footer {
            border-radius: 1rem;
        }

        .shepherd-header {
            padding: 1rem 2.5rem 0 2.5rem !important;
        }

        .shepherd-text {
            padding: .5rem 2.5rem 1rem;

            .sheperd_title {
                margin-bottom: 1rem;
            }

            .sheperd_desc {
                font-size: 16px;
                color: $headings-color;
                font-family: 'Bicyclette';
            }
        }

        .shepherd-footer {
            padding: 0 2.5rem 1rem !important;
            justify-content: start;
        }
    }

    .shepherd-button {
        padding: .5rem 1rem;
    }
}

.site-banner {
    padding: 50px 0;
    position: relative;
    width: 100%;

    .site-banner-img-1,
    .site-banner-img-2 {
        height: 260px;
        width: 260px;
        object-fit: cover;
        object-position: top;
        position: absolute;
        bottom: 0;
    }

    .site-banner-img-1 {
        left: -40px;
    }

    .site-banner-img-2 {
        right: -40px;
    }
}

@media (max-width: 1499px) {
    .site-banner {

        .site-banner-img-1,
        .site-banner-img-2 {
            height: 180px;
            width: 180px;
        }
    }
}

@media (max-width: 575px) {
    .site-banner {

        .site-banner-img-1,
        .site-banner-img-2 {
            display: none;
        }
    }
}


.close-icon-main {
    position: relative;

    .close-icon-child {
        position: absolute;
        bottom: 0;
    }

    .close-icon-child {
        right: -47px;
        top: -44px;
        background: var(--bs-primary-tint-60);
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 50%;
    }
}

.coaches-box {
    position: relative;
    text-align: center;

    .coaches-title {
        a {
            color: $headings-color;
            transition: all 0.3s ease-in;
        }
    }

    .image {
        position: relative;
        display: inline-block;

        &::before {
            content: "";
            height: 59px;
            width: 59px;
            border-radius: 50%;
            border: 5px solid transparent;
            display: inline-block;
            position: absolute;
            left: 50%;
            bottom: -1px;
            transform: translateX(-50%);
            transition: all 0.3s ease-in;
        }

        &.no-border {
            &::before {
                content: none;
            }

            .coaches-info {
                right: -20px;
            }
        }
    }

    .coaches-image {
        position: relative;
        z-index: 1;
        height: 70px;
        width: 70px;
        object-fit: cover;
    }

    .coaches-info {
        position: absolute;
        top: 18px;
        right: -28px;
        border: none;
        background: transparent;
    }

    &:hover {
        cursor: pointer;

        .coaches-title {
            a {
                color: var(--bs-primary);
            }
        }

        .image {
            &::before {
                border-color: var(--bs-primary);
            }
        }
    }
}

.card-title {
    color: var(--bs-secondary);
}

.guru-modal {
    .modal-dialog {
        max-width: 920px;
        height: 100%;
    }
}

.modal.show .modal-dialog {
    display: flex;
    align-items: center;
    height: 100vh;
}



.btn {
    &.select_guru_btn {
        color: $headings-color !important;

        &:hover {
            background-color: var(--bs-primary) !important;
            border-color: transparent !important;
            color: var(--bs-white) !important;
        }
    }
}

.btn-check {
    &:checked+ {
        .btn {
            &.select_guru_btn {
                color: var(--bs-white) !important;
            }
        }
    }
}

.video_container {
    iframe {
        height: 100%;
        width: 100%;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: 0.5rem;
        padding: 1rem;
    }
}

.breadcrumb-main {
    width: 100%;
    background: #EBFDED;
    height: 130px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolkit-contain {
    margin: 3%;
    background: white;
    height: 698px;
    padding: 3%;
}

.toolkit-div {
    background-color: #F9F9F9;
    height: 405px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
}


.player .vp-video-wrapper .vp-preview {
    background-image: none !important;
}

.table-custom {
    table {
        border-collapse: separate;

        td {
            border-radius: 4px;
            padding: 8px 12px;
        }
    }
}

.font-size-18 {
    font-size: 18px;
}

.banner-home {
    padding: 50px 0 220px;

    &.site-banner {
        .site-banner-img-1 {
            left: 5px;
        }

        .site-banner-img-2 {
            right: 0;
        }
    }

    .close-icon-main {
        .close-icon-child {
            right: 20px;
            top: -27px;
        }
    }
}

.line-height-normal {
    line-height: normal;
}

.modal-coaches-info {
    --bs-modal-header-padding: 1.25em;
    --bs-modal-padding: 1.25em;

    .modal-coaches-image {
        height: 84px;
        width: 84px;
        object-fit: cover;
    }

    .modal-body {
        padding-bottom: 50px;
    }
}

@media (max-width: 1399.98px) {
    .banner-home {
        padding: 50px 30px 220px;

        .close-icon-main {
            .close-icon-child {
                right: 0;
            }
        }
    }
}

@media (max-width: 575px) {
    .banner-home {
        padding: 50px 30px;
    }
}

.maximized {
    width: 90%;
    position: absolute;
    right: 100px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 176px 506px 428px;
    z-index: 1050;
    background: white;
    border-radius: 5px;
    padding-right: 0;
    padding-left: 0;
}

.tooltip-custom {
    background: green;
    color: white;
    border-radius: 9px;
    padding: 4px 7px;
}

.task-model {
    max-width: auto;
}

@media (min-width: 576px) {
    .task-model .modal-dialog {
        max-width: 90%;
        // margin-right: 5px;
        // margin-left: 5px;
        height: auto !important;
        max-height: 90vh !important
    }
}

.task-model .modal-content {
    min-height: 90vh;
    height: auto;
    max-height: 90vh;
    overflow: auto;
}

.tasklist td {
    border: 1px solid #F0F0F0 !important;
    padding: 10px !important;
    text-align: center;
}

.tasklist tr {
    margin: 10px 0 !important;
}

.tasklistTable th {
    font-weight: 500;
}

.chat-button {
    display: flex;
    align-items: center;
    background-color: #EBF8ED;
    border: none;
    border-radius: 6px;
    /* Rounded corners */
    padding: 8px 12px;
    cursor: pointer;
}

.chat-button span {
    /* Green text color */
    font-size: 13px;
    margin-right: 10px;
}

.chat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-icon svg {
    width: 17px;
    height: 16px;
}

.my-task-list {
    height: 175px;
    overflow-y: auto;
}

.my-task-list::-webkit-scrollbar {
    width: 6px !important;
}

/* Optional: Custom scrollbar styling */
.my-task-list::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Change color to your preference */
    border-radius: 10px;
    /* Rounded corners */
}

.my-task-list::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Change color on hover */
}

.custome {
    &::-webkit-scrollbar {
        width: 12px !important;
        background-color: #F5F5F5 !important;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        border-radius: 10px !important;
        background-color: #F5F5F5 !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        // background-color: #D62929 !important;
    }
}

.check-with-linethrough {
    .form-check-input {
        &:checked {
            &+label {
                text-decoration: line-through;
            }
        }
    }
}

.task-model {
    .task-modal-close {
        position: sticky;
        top: 30px;
        left: 96%;
    }
}

@media(max-width: 767px) {
    .task-model {
        .task-modal-close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
}

@media(max-width: 350px) {
    .task-model {
        .task-modal-close {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}


// config api

.icons-colors {
    color: var(--bs-secondary);
    background: var(--bs-secondary-tint-90);
    border-color: var(--secondary);
}

.icons-colors:focus {
    color: var(--bs-secondary);
    background: var(--bs-secondary-tint-90);
    border-color: var(--bs-secondary);
}

.sidebar-base .nav-item .nav-link:not(.disabled) {
    .gurus {

        color: var(--bs-secondary);
    }
}

.gurus {
    color: var(--bs-secondary);
}

.icons-colors:hover {
    color: var(--bs-secondary-tint-90) !important;
    background: var(--bs-secondary);
    border-color: var(--bs-secondary);
}

.icons-colors-filled {
    color: var(--bs-secondary-tint-90);
    background: var(--bs-secondary);
    border-color: var(--bs-secondary);
}

.icons-colors-filled:hover {
    color: var(--bs-secondary-tint-90);
    background: var(--bs-secondary);
    border-color: var(--bs-secondary);
}

.bg-success {
    background-color: var(--bs-secondary) !important
}

.text-success {
    color: var(--bs-secondary) !important;
}

.toolkit-table {
    td {
        background-color: var(--bs-btn-color);
        box-shadow: inset 0 0 0 9999px var(--bs-btn-color);
    }
}

.bg-soft-secondary {
    background-color: var(--bs-secondary-tint-90) !important;
    color: var(--bs-secondary)
}

.bg-secondary {
    background-color: var(--bs-secondary) !important
}

.header-bar {
    color: var(--bs-primary)
}

.btn-check:checked+.btn.btn-border {
    color: white
}

.btn.btn-border {
    color: var(--bs-primary);
}



// scroll bar

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(222, 222, 222, .75);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
    border-radius: 8px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-button:vertical:increment,
::-webkit-scrollbar-button:vertical:decrement {
    display: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 1s linear infinite;
}


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
    body {
        margin: 0 !important;
    }
}

.main-container {
    width: 100% !important;
    --ckeditor5-preview-height: 700px;
    font-family: 'Lato';
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.ck-content {
    font-family: 'Lato';
    line-height: 1.6;
    word-break: break-word;
}

.editor-container__editor-wrapper {
    display: flex;
    width: fit-content;
}

.editor-container_document-editor {
    border: 1px solid var(--ck-color-base-border);
    margin: 10px;
    border-radius: 8px;
}

.editor-container_document-editor .editor-container__toolbar {
    display: flex;
    position: relative;
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar>.ck.ck-toolbar {
    flex-grow: 1;
    width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container_document-editor .editor-container__menu-bar>.ck.ck-menu-bar {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: none;
}

.editor-container_document-editor .editor-container__editor-wrapper {
    max-height: var(--ckeditor5-preview-height);
    min-height: var(--ckeditor5-preview-height);
    overflow-y: scroll;
    width: 100% !important;
    // background: var(--ck-color-base-foreground);
}

.ck.ck-menu-bar__menu.ck-menu-bar__menu_top-level>.ck-menu-bar__menu__button :hover {
    background: var(--bs-secondary-tint-90) !important;
    color: var(--bs-secondary);
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
    background: var(--bs-secondary-tint-90) !important;
    color: var(--bs-secondary);
}

.editor-container_document-editor .editor-container__editor {
    margin-top: 28px;
    margin-bottom: 28px;
    height: 100%;
    width: 100%;
}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
    box-sizing: border-box;
    // min-width: calc(210mm + 2px);
    // max-width: calc(210mm + 2px);
    min-height: 297mm;
    height: fit-content;
    padding: 20mm 12mm;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
    flex: 1 1 auto;
    margin-left: 2%;
    margin-right: 2%;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    border: none !important;
}

.ck-powered-by {
    display: none !important;
}


// animation

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(10);
    }
}

.ck-editor-animation {
    animation: fadeIn 1s ease-in-out;
}

.community-info .btn-community-info {
    position: absolute;
    top: -12px;
    right: 26px;
}

.btn-check:focus+.btn,
.btn:focus {
    background-color: transparent;
}

.modal-header .btn-close {
    margin: 0;
}

.no-service-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    text-align: center;
    flex-direction: column;
    padding: 40px 20px;
}

.no-service-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.no-service-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--bs-primary);
    margin-bottom: 10px;
}

.no-service-text {
    font-size: 16px;
}

.mini-icons-color {
    stroke:var(--bs-primary); /* Change this to your desired color */
}

.chat-box-userimage{
    height: 55px;
    max-width: 100%;
    margin: 0 5px;
}

.chat-box-username{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media(max-width: 1849.98px){

    .chat-box-username{
        font-size: 22px;
    }
}

@media(max-width: 1450px){
    .chat-box-userimage {
        height: 50px;
    }

    .chat-box-username{
        font-size: 18px;
    }
}

@media(max-width: 1450px) and (min-width: 1199.98px){
    .chat-card-header {
        padding: 0 24px 0 0;
    }
}

.ck-content .table table{
    width: 0 !important;
    height: 0 !important;
}

.truncate {
    display: inline-block;
    width: 175px; /* Set your desired width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .image-container:before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, .4);
    width: 100%;
    height: 100%;
}
svg.eye-icon {
    width: 25px;
    height: 25px;
    position: absolute;
    transition: opacity 0.3s;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    margin: auto;
}
.spinner {
    border: 8px solid #f3f3f3; 
    border-top: 8px solid var(--bs-secondary);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  /* Animation for the spinner */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .video-modal{
    .video-modal-close{
        padding: 5px;
        left: 96%;
    }
    @media(max-width: 1180px){
        .video-modal-close{
            left: 91%;
        }
    }
  }

  .dark{
    .iq-accordian {
       .card {
           background-color: #222738 !important;
       }
   }
   .iq-skeleton{
       background:linear-gradient(90deg, #8b8888 25%, #8b8888 50%, #8b8888 75%); 
   }
   .iq-img-skeleton{
       background:linear-gradient(90deg, #8b8888 25%, #8b8888 50%, #8b8888 75%); 
   }
    }