// nav-rounded

.sidebar{
    &.navs-rounded{
        .sidebar-body{
            .nav-item {
                &.static-item {
                    padding: 0;
                }
                padding: 0;
                .nav-link{
                    padding: .5rem 1rem;
                    border-top-right-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }
            }
        }
    }
}
