.footer {
    font-size: $footer-font-size;
    background: $white;
    position: fixed;
    left: var(--sidebar-width);
    right: 0;
    bottom: 0;

    .footer-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.5rem calc(var(--bs-gutter-x, 1rem) * 2);
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
    &.sticky{
        position: sticky;
        bottom: 0;
    }
    &.glass{
        background-color: rgba($white, .1);
        border-top: 1px solid $white;
        backdrop-filter: blur(10px);
    }
}

@media(min-width: 767.98px){
    .main-content{
        margin-bottom: calc(var(--footer-height) + .5rem);
    }
}

@media(max-width: 1199.98px){
    .footer{
        left: 0;
    }
}

@media(max-width: 767.98px){
    .footer{
        position: static;
    }
}