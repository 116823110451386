.sidebar.sidebar-mini+.main-content {
    .iq-header-img {
        transition: all 400ms;
    }
}

.nav {
    .search-input {
        &.input-group {
            &:focus-within {

                .input-group-text,
                .form-control {
                    border-color: var(--#{$variable-prefix}primary-tint-40);
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .nav {
        .logo-main {
            .logo-mini {
                display: none;
            }

            .logo-normal {
                width: 100%;
                max-width: 140px;
                display: block;
                height: auto;
                margin: auto;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .container-fluid {
        &.content-inner {
            &.mt-n5 {
                margin-top: unset !important;
            }
        }
    }

    .nav {
        .navbar-brand {
            flex: 1;
        }

        .logo-main {
            width: 100%;
            text-align: center;

            .logo-mini {
                display: none;
            }
        }
    }
}