.btn {
    &.btn-border {
        @include button-outline-variant($primary);
        color: $secondary;
        border: $border-width * 2 solid $border-color;
        padding: $spacer*.5 $spacer;
        box-shadow: 0px 2px 10px 0px rgba(196, 186, 196, 0.30);
        &.z-border-badge{
            color: var(--bs-black) !important;
            box-shadow: 0px 2px 10px 0px rgba(196, 186, 196, 0.30);
            &:hover {
                background: var(--bs-secondary) !important;
                border-color: unset !important;
                color: var(--bs-white) !important;
            }
            .btn-check:checked + &{
                background: var(--#{$variable-prefix}secondary) !important;
                color: var(--#{$variable-prefix}white) !important;
            border-color: var(--#{$variable-prefix}secondary);
            }
        }
        &:hover {
            background: transparent;
            border-color: var(--#{$variable-prefix}primary) !important;
            color: var(--#{$variable-prefix}primary);
        }
        .btn-check:checked + &,
        .btn-check:active + &,
        &:active, &.active{
            background: var(--#{$variable-prefix}primary);
            color: var(--#{$variable-prefix}white);
            border-color: var(--#{$variable-prefix}primary);
            &.bg-transparent{
                color: var(--#{$variable-prefix}primary);
            }
        }
    }
}
