// left-bordered

.sidebar{
    &.left-bordered{
        .sidebar-body{
            &:not(.sub-nav) {
                >.nav-item {
                    &.active, &:not(.static-item) {
                        &::before {
                            left: -35%;
                        }
                    }
                }
                .nav-item {
                    &:not(.static-item){
                        position: relative;
                        &::before{
                            position: absolute;
                            content: '';
                            width: 4px;
                            height: 0%;
                            background-color: var(--#{$variable-prefix}secondary);
                            top: 0;
                            left: 0;
                            border-top-right-radius: .25rem;
                            border-bottom-right-radius: .25rem;
                            z-index: 1;
                            transition: var(--sidebar-transition);
                            transition-duration: var(--sidebar-transition-duration);
                            transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                        }
                        &:hover{
                            &::before {
                                height: 100%;
                                transition: var(--sidebar-transition);
                                transition-duration: var(--sidebar-transition-duration);
                                transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                                z-index: 1;
                            }
                        }
                        .sub-nav {
                            .nav-item {
                                &:hover, &.active{
                                    &::before {
                                        content: unset;
                                        height: 0;
                                    }
                                }
                            }
                        }
                        &.active {
                            &::before {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
        // &:not(.sidebar-color){
        //     .nav-item {
        //         &:not(.static-item){
        //             .nav-link{
        //                 &.active, &[aria-expanded=true], &:hover{
        //                     // color: var(--#{$variable-prefix}primary);
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}
